<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :color="color"
                :label="label"
                :outlined="outlined"
                :dense="dense"
                :disabled="disabled"
                :value="formattedDate"
                :rules="rules"
                :placeholder="placeholder"
                :solo="solo"
                :rounded="rounded"
                :class="customClass"
                :flat="flat"
                :hide-details="hideDetails"
                :style="inlineStyle"
                append-icon="mdi-calendar-outline"
                readonly
                clearable
                @click:clear="valueLocal = null"
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
            :color="color"
            :max="max"
            :min="min"
            v-model="valueLocal"
            @input="handleInput"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import dateMixin from "@/mixins/date";

export default {
    name: "base-date-picker",

    props: {
        value: String,
        label: String,
        outlined: Boolean,
        dense: Boolean,
        disabled: Boolean,
        rules: Array,
        max: String,
        placeholder: String,
        solo: Boolean,
        rounded: Boolean,
        flat: Boolean,
        hideDetails: Boolean,
        inlineStyle: [String, Object],
        customClass: String,
        color: String,
        width: String,
        min: String,
    },

    data() {
        return {
            valueLocal: this.value,
            menu: false,
        };
    },

    computed: {
        formattedDate() {
            return dateMixin.toSimpleDate(this.value);
        },
    },

    watch: {
        value(val) {
            this.valueLocal = val;
        },
    },

    methods: {
        handleInput(e) {
            this.$emit("input", e);
            this.menu = false;
        },
    },
};
</script>
